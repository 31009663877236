import React from 'react';
import Navbar from '../../../components/Navbar/Navbar';

import './Restaurant.css';
import Footer from '../../../components/Footer/Footer';

const Restaurant = () => {
    return (
        <>
            <Navbar />

            <div className='restaurant'>
                <h1>Ayalla Restaurant</h1>
            </div>

            <div className='resWrapper'>
                <img src="/assets/serviceImages/rest.jpg" alt="ayalla restaurant" />

                <div className='resDetails'>
                    <span>Details</span>
                    <p>
                        We serve local as well as Chinese, Indian and other assorted dishes including KKF, national and continental meals.
                    </p>
                </div>


            </div>
            <Footer />

        </>
    )
}

export default Restaurant;