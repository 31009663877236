import React from 'react';

import './SuccessfulPage.css';

import { MdDone } from "react-icons/md";
import { Link } from 'react-router-dom';
const SuccesfulPage = () => {
    return(
        <div className='success'>
            <div className='successWrapper'>
                <div className='successBox'>
                    <div className='successIcon'>
                        <MdDone size={80} style={{ color: '#006400' }}  />
                    </div>
                    <h2>Thank You!</h2>
                    <p>Your reservation is received. kindly, check your email for more details.</p>
                </div>
                <Link to="/" className='link'>
                    <button className='shomeBtn'>Vist Home</button>
                </Link>
            </div>
        </div>

    )
}

export default SuccesfulPage;