import React from 'react';
import './dashboardSearch.css';
import { MdAccountCircle, MdSearch } from "react-icons/md";


const DashboardSearch = () =>{
    return(
        <div className='dashboardSearch'>
        <div className='dashboardSearchForm'>
            <MdSearch size={25} />
            <input type="text" placeholder='Search bookings' />
        </div>

        <div className='currentUser'>                   
                <p>Hi, Admin</p>

                <MdAccountCircle size={60} />
            
        </div>
</div>

    )
}

export default DashboardSearch;