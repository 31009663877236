import React, { useState, useContext } from 'react';
import './Login.css';
import { Context } from '../../Context';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

const LoginForm = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const { userLogin, error, loading } = useContext(Context)
    const handleLogin = (e) => {
        e.preventDefault();
        const data = { email, password }
        userLogin(data);
        setTimeout(() => {
            navigate("/dashboard");
        }, 1000)
        if (error) {
            return error
        } else {
            toast('Login Successful!');
        }
        setEmail('');
        setPassword('');
    }

    return (
        <div className='login'>
            <ToastContainer />
            <div className='login-container'>
                <div className='login-logo'>
                    <img src='./assets/logo.png' alt='ayalla-logo' />
                </div>
                <div className='login-header-title'>
                    <h2>Login to Ayalla Dashboard</h2>
                </div>
                <form className='login-form-wrapper' onSubmit={handleLogin}>
                    <div className='loginForm'>
                        <input type='email' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Email' autoComplete='off' />
                    </div>

                    <div className='loginForm'>
                        <input type='password' value={password} onChange={(e) => setPassword(e.target.value)} placeholder='Password' autoComplete='off' />
                    </div>

                    <span className='login-error'>{error}</span>
                    <button>{loading ? 'Processing...' : 'Login'}</button>
                </form>

                <div className='login-copy'>
                    <span>Copyright &copy; Ayalla Hotels Limited.</span>
                    <p>Developed By:  SOFTMERIT ICT SOLUTIONS</p>
                </div>
            </div>


        </div>
    )
}

export default LoginForm