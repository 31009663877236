import React from 'react';
import Navbar from '../../../components/Navbar/Navbar';

import './ComputerCentre.css';
import Footer from '../../../components/Footer/Footer';

const ComputerCentre = () => {
    return (
        <>
            <Navbar />

            <div className='computer'>
                <h1>Ayalla Computer Centre</h1>
            </div>

            <div className='computerWrapper'>
                <img src="/assets/serviceImages/computer-centre.jpeg" alt="ayalla computer centre" />

                <div className='computerDetails'>
                    <span>Details</span>
                    <p>
                        Our Computer Centre offers the following services; Printing, Internet, Typesetting and much more.
                    </p>
                </div>


            </div>
            <Footer />

        </>
    )
}

export default ComputerCentre;