import React from 'react';
import Navbar from '../../../components/Navbar/Navbar';

import './Bar.css';
import Footer from '../../../components/Footer/Footer';

const Bar = () => {
    return (
        <>
            <Navbar />

            <div className='bar'>
                <h1>Ayalla Bar</h1>
            </div>

            <div className='barWrapper'>
                <img src="/assets/serviceImages/bar.jpeg" alt="ayalla bar" />

                <div className='barDetails'>
                    <span>Details</span>
                    <p>
                        The hotel bar serves all ranges of wine such as Whisky, Brandy, and Alcohol etc.
                    </p>
                </div>


            </div>
            <Footer />

        </>
    )
}

export default Bar;