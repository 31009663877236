import React, { useContext, useState } from 'react';

import './DashboardSidebar.css';
import { Link, useNavigate, } from 'react-router-dom';
import { Context } from '../../Context';
const sideMenu = [
    {
        id: 0,
        title: 'Rooms Booked',
        link: '/dashboard'
    },
    {
        id: 1,
        title: 'View Rooms',
        link: '/dashboard/rooms'
    },
    {
        id: 2,
        title: 'Add New Room',
        link: '/dashboard/addnew'
    },
    // {
    //     id: 3,
    //     title: 'Add New Post',
    //     link: '/'
    // }
]
const DashboardSidebar = () => {
     const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(0);

    const { userLogOut }= useContext(Context)

    const handleSignOut = () =>{
        userLogOut()
        navigate("/login");
       
    }
    return (
        <div className='dashboardSidebar'>
            <div className='dsidebarWrapper'>
                <div className='sidebarLogo'>
                   <Link to="/dashboard" className='link'>
                   <img src="/assets/logo.png" alt='ayalla logo' />
                   </Link>
                </div>


                <div className='dsidebarLink'>
                    {sideMenu && sideMenu?.map((item, index) => (
                        <Link key={index} to={item.link} className='linkTwo'>
                            <span
                                onClick={() => {
                                    setActiveTab(index);
                                }}
                                
                                style={{
                                    color: activeTab === index ? 'darkblue' : 'black',
                                }}>{item.title}
                            </span>
                        </Link>
                    ))}
                </div>

                <div className='dsidebarLogout' onClick={handleSignOut}>
                    <button >LogOut</button>
                </div>

            </div>

        </div>
    )
}

export default DashboardSidebar;