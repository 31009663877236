
import axios from "axios";

const upload = async (roomimage) => {
    const data = new FormData();
    data.append('file', roomimage);
    data.append('upload_preset', "ayalla");

    try{
        const res = await axios.post('https://api.cloudinary.com/v1_1/softmerit/image/upload', data);
        const { url } = res.data;
        console.log(url, 'this cloud url image');
        return url;

    }catch(err){
        console.log(err);
    }

}

export default upload;