import React from 'react';
// import Swiper core and required modules
import { Navigation, Pagination, A11y, Autoplay, } from 'swiper';

import { Swiper, SwiperSlide, } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


import './Slide.css';
import { slideData } from '../../slideImagesData/slideData';

const Slide = () => {


    return (
        <div className="slideContainer">

            <Swiper
                // install Swiper modules
                modules={[Navigation, Autoplay, Pagination, A11y]}

                centeredSlides={true}
                autoplay={{
                    delay: 3500,
                    disableOnInteraction: false,
                }}
                spaceBetween={50}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}

                onSwiper={() => { }}
                onSlideChange={() => { }}

            >

                {slideData?.map((imgSlide) => (
                    <SwiperSlide key={imgSlide.id}>
                        <div className='overlay'></div>
                        <div className="swiper-slider">
                            <img src={imgSlide.img} alt="" />
                            {/* <div className="swiperContent">
                            <h1 className="swiperTitle">Luxury Experience</h1>

                            <p className="swiperDesc">We are focused on providing clients
                                with the highest level of comfort and
                                excellent affordable rates.
                            </p>
                        </div> */}
                        </div>
                    </SwiperSlide>
                ))}

            </Swiper>
        </div>
    )
}

export default Slide;