import React from 'react';
import "./Gallery.css";
import { galleryData } from '../../galleryData';

const Gallery = () =>{
    return(
        <div className='gallery'>
            <h1>OUR GALLERY</h1>
            <div className='gDivider'>
            <div className='divider' />
            <div className='divider middle' />
            <div className='divider' />
            </div>
            <div className='galleryWrapper'>
                {galleryData?.map((g)=>(
                    <div key={g.id} className='galleryBox'>
                        <img className="gImage" src={g.img} alt="" />
                        <span className='gText'>{g.title}</span>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Gallery;