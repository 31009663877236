import React, { useContext, } from 'react';

import './ViewRoom.css';
import DashboardSidebar from '../DashboardSidebar/DashboardSidebar';
import DashboardSearch from '../SearchForm/dashboardSearch';
import { Context } from '../../Context';


const ViewRoom = () => {

    const { loading, roomData } = useContext(Context);

    return (
        <div className='dashboard'>
          
            <div className='dashboardWrapper'>
                <div className='dashboardLeft'>
                    <DashboardSidebar />
                </div>

                <div className='dashboardRight' >

                    <DashboardSearch />
                    {/* search form */}
                    <h1>Ayalla Hotels Rooms</h1>
                    <p>Browse through our variety of rooms.</p>

                    {loading ? (
                        <p className='loading'>Loading...</p>
                    ) : (
                        <div className='dashboardRoomDetails'>
                            <div className='dashboardRoomWrapper'>
                                {roomData && roomData?.map((room) => (
                                    <div key={room._id} className='roomWrapper'>
                                        <div className='roomDetails'>
                                            <img src={room.roomimage} alt='' />
                                        </div>
                                        <div className='roomContent'>
                                            <span>
                                            <strong>
                                            Room Name:
                                            </strong> {room.roomname}</span>
                                            <p>
                                                <strong>
                                                Room Price:
                                                </strong> NGN {room.roomprice} </p>
                                            <p>
                                                <strong>
                                                Room Location:
                                                </strong> {room.roomlocation}</p>
                                        </div>

                                        {/* action button */}
                                        <div className='roomActionBtn'>
                                           <p>Edit Room</p>
                                        </div>
                                        {/* end of action button */}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}


                </div>

            </div>
           
        </div>
    )
}

export default ViewRoom;