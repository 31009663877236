import React from 'react';
import Navbar from '../../../components/Navbar/Navbar';

import './Gym.css';
import Footer from '../../../components/Footer/Footer';

const Gym = () => {
    return (
        <>
            <Navbar />

            <div className='gym'>
                <h1>Ayalla Gymnasium</h1>
            </div>

            <div className='gymWrapper'>
                <img src="/assets/serviceImages/gym.jpg" alt="ayalla bar" />

                <div className='gymDetails'>
                    <span>Details</span>
                    <p>
                        Keep fit and stay in shapes is our sure best. You enjoy our ultra-modern gym.
                    </p>
                </div>


            </div>
            <Footer />

        </>
    )
}

export default Gym;