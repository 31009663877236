import React from 'react';
import { Navigate } from "react-router-dom";

export const ProtectedRoute = ({children})=>{

  // protecting routes
  const user = JSON.parse(localStorage.getItem('userInfo'));
  if(!user) return <Navigate to="/login" />


  return children;
}