export const slideData = [
    {
        id: 0,
        img: require('./sImages/slide1.jpg'),
    },
    {
        id: 1,
        img: require('./sImages/slide2.jpg'),
    },
    {
        id: 2,
        img: require('./sImages/slide3.jpg'),
    },
    {
        id: 3,
        img: require('./sImages/slide4.jpg'),
    },
    {
        id: 4,
        img: require('./sImages/slide5.jpg'),
    },
    {
        id: 5,
        img: require('./sImages/slide6.jpg'),
    }
]