export const galleryData = [
    {
        id: 0,
        title: "Ayalla Hotels",
        img: require("../src/gallery/g1.jpeg"),
    },
    {
        id: 1,
        title: "Swimming",
        img: require("../src/gallery/g2.jpeg"),
    },
    {
        id: 2,
        title: "Hotel Room",
        img: require("../src/gallery/g3.jpeg"),
    },
    {
        id: 3,
        title: "Ayalla Bar",
        img: require("../src/gallery/g4.jpeg"),
    },
    {
        id: 4,
        title: "Pool ",
        img: require("../src/gallery/g5.jpeg"),
    },
    {
        id: 5,
        title: "Executive Room",
        img: require("../src/gallery/g6.jpeg"),
    }
]