import React from 'react';

import './About.css';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import { MdOutlinePix } from "react-icons/md";

const About = () => {
    return (
        <>
            <Navbar />

            <div className='about'>
                <h1>About Us</h1>
            </div>

            <div className='aboutWrapper'>

                <h1>AYALLA HOTELS LIMITED</h1>
                <p>
                    Ayalla Hotels Limited has repeatedly demonstrated its ability to meet demanding schedules for
                     major clients in both local and international competitive biddings in various categories of operation. 
                     We are also committed to pushing boundaries of technology to maximize our clients profit objectives.
                </p>

                <p>
                The company retains the services of crest consultant Hospital as its retainership clinic for medical 
                services and other centres as the need arises. A well-equipped First Aid boxes are provided in our entire 
                front desk department and other strategic locations as well as work sites in the case of external operation.   
                </p>

                <p>
                The company also have trained first aiders and fire fighters who take full responsibility of their schedules. 
                The hotel is technically efficient and well positioned to carry out onshore/offshore operations in its field of specializations. 
                </p>

                <p>
                The hotel provides catering and accommodation services to major construction companies, energy companies, oil installation and servicing companies. 
                The hotel also has a long standing relationship with several world class companies including Shell Petroleum Company of Nigeria (SPDC), DAWOO, Chevron Nig. Ltd, UN Organization, LNG, etc.   
                </p>


                <div className='aboutContent'>
                    <div className='col'>
                        <h2>Our Vison</h2>
                        <p>
                        Our vision is to provide a benchmark in the hospitality industry 
                        by constantly exceeding the expectations of our guests, associates and stakeholders, with the light of warmth and hospitality. 
                        </p>
                    </div>

                    <div className='col'>
                        <h2>Our Mission</h2>
                        <p>
                        To stand out as Nigeria&apos;s pre-eminent brand in the hotel and hospitality industry, the first choice of guests who 
                        treasure leisure and pleasure. The hotel is efficient in service delivery maintaining an unwavering focus in archiving our ultimate goal.
                        </p>
                    </div>

                    <div className='col'>
                        <h2>Who We Are</h2>
                        <p>
                        Ayalla Hotels Limited; It also forms the basis of standard pre-qualification requirement.
                        The hotel is technically efficient and well positioned to carry out onshore/offshore operations in field of its various specializations.
                        </p>
                    </div>
                </div>

                <div className='aboutContentTwo'>
                    <div className='aboutLeft'>
                        <h2>Specialist Manpower</h2>
                        <p>
                        Our Manpower includes, Engineers of various disciplines such as;
                        Electrical, Civil, management experts, system engineers QA/QC officers 
                        who have local and foreign accolades, other include, chefs, continental, 
                        national, Chinese, Indian and other local cooks, tab technician, project engineers, 
                        procurement office. The hotel has the proficiency to organize and deliver world class 
                        workshops, seminars and technical training. Because we believer in diversity hence, 
                        our focus is to train and retrain.  
                        </p>

                        <h2>
                        Safety Programming
                        </h2>
                        <ul>
                            <li>
                            <MdOutlinePix />
                            Customized specific plans
                            </li>
                            <li>
                            <MdOutlinePix />
                            Safety audits etc.
                            </li>
                            <li>
                            <MdOutlinePix />
                            Contractor Audits
                            </li>
                            <li>
                            <MdOutlinePix />
                            Complete Safety programmer
                            </li>
                        </ul>
                    </div>

                    <div className='aboutRight'>
                        <h2>Consulting Services</h2>
                        <p>
                        Ayalla Hotels Limited provides major project management 
                        and technical consulting services to achieve business result 
                        through the effective application of business managerial skill. 
                        Our team of experience staff receives periodic coaching and attend 
                        regular workshops that have caused their renovation through a range of consulting services.
                        </p>

                        <h2>
                        Project Support Preliminary estimate and budget
                        </h2>
                        <ul>
                        <li>
                            <MdOutlinePix />
                        Construction of flexibility reviews
                        </li>

                        <li>
                            <MdOutlinePix />
                        Preliminary execution plan
                        </li>
                        </ul>
                    </div>
                </div>

                <div className='aboutValue'>
                    <div className='aboutValueBox'>
                        <h2>
                        Training Outline
                        </h2>
                        <ul>
                        <li>
                        <MdOutlinePix />
                        Security
                        </li>
                        <li>
                        <MdOutlinePix />
                        Basic service etiquette
                        </li>
                        <li>
                        <MdOutlinePix />
                        Attributes of hospitality
                        </li>
                        <li>
                        <MdOutlinePix />
                        House-keeping
                        </li>
                        <li>
                        <MdOutlinePix />
                        Personal hygiene
                        </li>
                        <li>
                        <MdOutlinePix />
                        Food safety & Hygiene Practices
                        </li>
                        <li>
                        <MdOutlinePix />
                        Hazard Control (HACCP)
                        </li>
                        <li>
                        <MdOutlinePix />
                        Fire Drill and Basic First Aid etc.
                        </li>
                        </ul>
                    </div>

                    <div className='aboutValueBox'>
                        <h2>
                        Our core value
                        </h2>
                        <ul>
                            <li>
                            <MdOutlinePix />
                            Consistency in service delivery
                            </li>
                            <li>
                            <MdOutlinePix />
                            Security alertness
                            </li>
                            <li>
                            <MdOutlinePix />
                            Commitment to customer
                            </li>
                            <li>
                            <MdOutlinePix />
                            Capacity building
                            </li>
                            <li>
                            <MdOutlinePix />
                            Quality Control &amp; Assurance
                            </li>
                            <li>
                            <MdOutlinePix />
                            Compliance and dynamism
                            </li>
                            <li>
                            <MdOutlinePix />
                            Diversity amongst our people
                            </li>
                            <li>
                            <MdOutlinePix />
                            Youth Empowerment &amp; Building
                            </li>
                        </ul>
                    </div>
                </div>
               
            </div>
            <Footer />
        </>
    )
}

export default About;