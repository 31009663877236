import React from 'react';
import Navbar from '../../../components/Navbar/Navbar';

import './ConferenceRoom.css';
import Footer from '../../../components/Footer/Footer';

const ConferenceRoom = () => {
    return (
        <>
            <Navbar />

            <div className='meeting'>
                <h1>Ayalla Meeting Room</h1>
            </div>

            <div className='meetingWrapper'>
                <img src="/assets/serviceImages/meetingroom.jpg" alt="ayalla meeting room" />

                <div className='meetingDetails'>
                    <span>Details</span>
                    <p>
                        Our meeting room can accommodate large number of people and provide all the necessary assistance in terms of your technology requirements, food & beverage preferences.
                    </p>
                    <p>
                        A range of audio-visual equipment is available including LCD projectors, overhead projectors, PA systems as well as internet and Wi-Fi connections in all the meeting room types.
                    </p>
                </div>


            </div>

            <Footer />
        </>
    )
}

export default ConferenceRoom;