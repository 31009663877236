import React from 'react';

import './Dashboard.css';
import DashboardSidebar from '../DashboardSidebar/DashboardSidebar';
import DashboardSearch from '../SearchForm/dashboardSearch';

const Dashboard = () => {
    return (
        <div className='dashboard'>
            <div className='dashboardWrapper'>
                <div className='dashboardLeft'>
                    <DashboardSidebar />
                </div>

                <div className='dashboardRight' >
                   
                    <DashboardSearch />
                    {/* search form */}
                    <h1>Welcome, Admin</h1>
                    <p>You have 10 room reservations booked in Total.</p>

                    <div className='dashboardBook'>
                        <span>Customer&apos; Reservation Details</span>
                    
                    <div className='dashboardBookDetail'>
                            <div className='bookedCol'>
                            <div className='bookBox'>
                                <span>Room Name</span>
                                
                                <p>Executive Room</p>
                            </div>

                            <div className='bookBox'>
                                <span>Room Amount</span>
                                <p>NGN 27,560</p>
                            </div>

                            <div className='bookBox'>
                                <span>Hotel Location</span>
                                <p>Yenagoa</p>
                            </div>

                            <div className='bookBox'>
                                <span>Check In Date</span>
                                <p>31 May, 2023</p>
                            </div>

                            <div className='bookBox'>
                                <span>Check Out Date</span>
                                <p>1 June, 2023</p>
                            </div>
                            </div>
                            {/* end of book col */}
                    </div>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default Dashboard;