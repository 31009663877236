'use client';
import './App.css';
//import Navbar from './components/Navbar/Navbar';
import Home from './pages/Home/Home';
import React, { useState } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Outlet
} from "react-router-dom";
import Restaurant from './pages/ServicesPage/Restaurant/Restaurant';
import Bar from './pages/ServicesPage/Bar/Bar';
import ConferenceRoom from './pages/ServicesPage/ConferenceRoom/ConferenceRoom';
import Gym from './pages/ServicesPage/Gym/Gym';
import ComputerCentre from './pages/ServicesPage/ComputerCentre/ComputerCentre';
import EventHall from './pages/ServicesPage/EventHall/EventHall';
import SwimmingPool from './pages/ServicesPage/SwimmingPool/SwimmingPool';
import ErrorPage from './components/ErrorPage/ErrorPage';
import About from './pages/AboutUs/About';
import YenagoaRoom from './pages/OurHotel/YenagoaRoom/YenagoaRoom';
import AyallaVIP from './pages/OurHotel/AyallaVIPRoom/AyallaVIP';
// import AbujaRoom from './pages/OurHotel/AbujaRoom/AbujaRoom';
import BookingPage from './pages/BookingPage/BookingPage';
import SuccesfulPage from './pages/SuccessfulBooking/SuccessPage';
import Dashboard from './dashboard/Dashboard/Dashboard';
import AddHotel from './dashboard/AddHotel/AddHotel';
import ViewRoom from './dashboard/ViewRooms/ViewRoom';
import ScrollTop from './components/ScrollTopHandler/ScrollToTop';
import LoginForm from './dashboard/Login/Login';
import { ProtectedRoute } from './ProtectedRoutes/ProtectedRoute';

const Layout = () => {
  // HANDLE PRELOADER EFFECT
  const [loading, setLoading] = useState(true);
  const loader = document.getElementById('loader');

  if (loader) {
    setTimeout(() => {
      loader.style.display = 'none';
      setLoading(false);
    }, 2000)
  }


  return (
    !loading && (
      <div className='App'>
        {/* <Navbar /> */}
        <ScrollTop>
          <Outlet />
        </ScrollTop>
      </div>
    )
  )
}
const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: "/booking/:id",
        element: <BookingPage />
      },
      {
        path: "/dashboard",
        element: (<ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>)
      },
      {
        path: "/login",
        element: <LoginForm />
      },
      {
        path: "/dashboard/rooms",
        element: (<ProtectedRoute>
          <ViewRoom />
        </ProtectedRoute>)
      },
      {
        path: "/dashboard/addnew",
        element: (<ProtectedRoute>
          <AddHotel />
        </ProtectedRoute>)
      },
      {
        path: "/successful-booking",
        element: <SuccesfulPage />
      },
      {
        path: "/about-us",
        element: <About />
      },
      {
        path: `/yenagoa-hotel`,
        element: <YenagoaRoom />
      },
      // {
      //   path: "/abuja-hotel",
      //   element: <AbujaRoom />
      // },
      {
        path: "/ayalla-vip-business-apartment",
        element: <AyallaVIP />
      },
      {
        path: "/restaurant",
        element: <Restaurant />
      },
      {
        path: "/bar",
        element: <Bar />
      },
      {
        path: "/conference",
        element: <ConferenceRoom />
      },
      {
        path: "/gym",
        element: <Gym />
      },
      {
        path: "/computer-centre",
        element: <ComputerCentre />
      },
      {
        path: "/event-halls",
        element: <EventHall />
      },
      {
        path: "/swimming-pool",
        element: <SwimmingPool />
      }
    ]
  },
]);

function App() {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
