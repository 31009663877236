import React from 'react';
import Navbar from '../../../components/Navbar/Navbar';

import './SwimmingPool.css';
import Footer from '../../../components/Footer/Footer';

const SwimmingPool = () => {
    return (
        <>
            <Navbar />

            <div className='swim'>
                <h1>Ayalla Swimming Pool</h1>
            </div>

            <div className='swimWrapper'>
                <img src="/assets/serviceImages/swimming.jpg" alt="ayalla swimming pool" />

                <div className='swimDetails'>
                    <span>Details</span>
                    <p>
                        Additional perks of being an in-house guest is to enjoy a day or night time swim. Access to the pool for outside guests are at an additional cost.
                    </p>
                </div>


            </div>
            <Footer />

        </>
    )
}

export default SwimmingPool;