import React from 'react';
import Navbar from '../../../components/Navbar/Navbar';

import './EventHall.css';
import Footer from '../../../components/Footer/Footer';

const EventHall = () => {
    return (
        <>
            <Navbar />

            <div className='event'>
                <h1>Ayalla Event Hall</h1>
            </div>

            <div className='eventWrapper'>
                <img src="/assets/serviceImages/eventhall.jpg" alt="ayalla event hall" />

                <div className='eventDetails'>
                    <span>Details</span>
                    <p>
                        Our event hall are very spacious and well furnished ideal for your seminars, Presentations and Wedding.
                    </p>
                </div>


            </div>
            <Footer />

        </>
    )
}

export default EventHall;