import React, { useEffect, useState } from 'react';

import './YenagoaRoom.css';
import Navbar from '../../../components/Navbar/Navbar';
import Footer from '../../../components/Footer/Footer';

import { Link } from 'react-router-dom';
import newRequest from '../../../utils/newRequest';

//import { MdOutlinePix } from "react-icons/md";

const YenagoaRoom = () => {
    const [yenagoaData, setYenagoaData] = useState([])
    const [loading, setLoading] = useState(false);

    const getYenagoaRooms = async () => {
        setLoading(true);
        try {
            const { data: { data } } = await newRequest.get('/yenagoa');
            setYenagoaData(data);
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }


    }

    useEffect(() => {
        getYenagoaRooms()
    }, [])


    return (
        <div className='yenagoapage'>
            <Navbar />

            <div className='yenagoa'>
                <h1>Ayalla Yenagoa Hotel</h1>
                <p>
                    Yenagoa Hotel rooms and its suites are decorated designed for your comfort and relaxation.

                </p>
            </div>

            <div className='yenagoa-wrapper'>


                {/* Rooms and suites */}

                <div className='yenagoa-room-container'>
                    {/* start of box */}

                    {loading ? (
                        <p className='loading'>loading...</p>
                    ) : (
                        <>
                            {
                                yenagoaData && yenagoaData?.map((room) => (
                                    <div key={room._id} className='yenagoa-box'>
                                        <div className='yenagoa-image'>
                                            <img src={room ? room.roomimage : '/assets/yenagoaImages/executiveyen.jpeg'} alt="" />
                                        </div>

                                        <div className='yenagoa-info'>
                                            <h2>{room.roomname}</h2>
                                            <span> ₦ {room.roomprice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}/ Per Night</span>
                                            {/* <p>
                                                {room.roomdesc}
                                            </p> */}
                                            
                                        </div>
                                        <Link to={`/booking/${room._id}`} className='link'>
                                                <button className='yengagoa-btn'>BOOK NOW</button>
                                            </Link>
                                    </div>
                                ))}
                        </>
                    )}
                </div>
                {/* end of box */}




            </div>
            <Footer />
        </div>
    )
}

export default YenagoaRoom;