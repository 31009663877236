import React, { useState } from 'react';

import './AddHotel.css';
import DashboardSidebar from '../DashboardSidebar/DashboardSidebar';
import DashboardSearch from '../SearchForm/dashboardSearch';

import upload from '../../upload';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import newRequest from '../../utils/newRequest';

const AddHotel = () => {

    const [roomname, setroomName] = useState("");
    const [roomprice, setroomPrice] = useState("");
    const [roomlocation, setroomLocation] = useState("");
    const [roomimage, setroomImage] = useState("");
    const [roomdesc, setroomDesc] = useState("");


    const handleSubmit = async (e) => {
        e.preventDefault();

        const url = await upload(roomimage);


        const roomData = {
            roomname,
            roomprice,
            roomlocation,
            roomdesc,
            roomimage: url,
        };

        try {

            await newRequest.post('/addroom', roomData);
            toast('Successfully submitted');
            setroomName("");
            setroomPrice("");
            setroomDesc("");
            setroomLocation("");
            setroomImage("");
        } catch (err) {
            console.log(err)
        }

    }

    // const photoImage = URL.createObjectURL(file);

    return (
        <div className='dashboard'>
            <div className='dashboardWrapper'>
                <div className='dashboardLeft'>
                    <DashboardSidebar />
                </div>

                <div className='dashboardRight' >

                    <DashboardSearch />
                    {/* search form */}
                    <h1>Add Room Information</h1>
                    <p>Kindly input all information correctly.</p>

                    <div className='dashboardForm'>
                        <form onSubmit={handleSubmit}>
                            <div className='dashboardInput'>
                                <div className='formGroup'>
                                    <label htmlFor=''>Room Name</label>
                                    <input value={roomname} type='text' placeholder='eg Executive '
                                        onChange={(e) => setroomName(e.target.value)} />
                                </div>

                                <div className='formGroup'>
                                    <label htmlFor=''>Room Price</label>
                                    <input value={roomprice} type='text' placeholder='eg 91000 '
                                        onChange={(e) => setroomPrice(e.target.value)} />
                                </div>

                                <div className='formGroup'>
                                    <label htmlFor=''>Room Location</label>
                                    <select value={roomlocation} name='location'
                                        onChange={(e) => setroomLocation(e.target.value)}>
                                        <option value="">Select Location</option>
                                        <option value="Yenagoa">Yenagoa</option>
                                        <option value="Abuja">Abuja</option>
                                        <option value="Vip">Ayalla Vip</option>
                                    </select>
                                </div>

                            </div>


                            {/* FILE UPLOAD */}
                            <div className='dashboardInput'>
                                <div className='formGroup'>
                                    <label htmlFor='uploadForm'>
                                        <div className='formImageBox'>

                                            {roomimage && roomimage ? (

                                                <img src={URL?.createObjectURL(roomimage)} alt='' />
                                            ) : (
                                                <div className='formImageText'>
                                                    <span>Click to Upload Image</span>
                                                </div>
                                            )}
                                        </div>
                                    </label>
                                    <input id="uploadForm" type='file'
                                        className='upload'
                                        onChange={(e) => setroomImage(e.target.files[0])} />
                                </div>

                                <div className='formGroup'>

                                    {/* description */}
                                    <div className='formGroup textarea'>
                                        <label htmlFor=''>Room Description</label>
                                        <textarea value={roomdesc}
                                            onChange={(e) => setroomDesc(e.target.value)}
                                            rows={5} cols={5} placeholder='Enter room description' />
                                    </div>
                                </div>

                            </div>


                            <button className='roomBtn'>Submit</button>
                        </form>
                        <ToastContainer />
                    </div>
                </div>


            </div>
        </div>
    )
}

export default AddHotel;