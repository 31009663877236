import React from 'react';

import { Link, useRouteError } from "react-router-dom";

import './ErrorPage.css';
export default function ErrorPage() {
  const error = useRouteError();
 
  return (
    <div id="error-page">
        <div className='errorWrapper'>

        <img src="/assets/logo.png" alt="ayalla logo" />
      <h1>Oops! 404</h1>
      <p>Sorry, the page you&#39;e looking for doesn&apos;t exist.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
      
      <Link to="/" className='link'>
      <button>Go to homepage</button>
      </Link>

        </div>
       
    </div>
  );
}