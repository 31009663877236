import React, { useEffect, useState } from 'react';

import './BookingPage.css';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
//import { MdOutlinePix } from "react-icons/md";
import { AiFillMinusCircle, AiFillPlusCircle } from "react-icons/ai";
import { useNavigate, useParams } from 'react-router-dom';

import newRequest from '../../utils/newRequest';
import { ToastContainer, toast } from 'react-toastify';
const BookingPage = () => {

    const [firstname, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [country, setCountry] = useState("");
    const [city, setCity] = useState("");
    const [arrival, setArrival] = useState("");
    const [address, setAddress] = useState("");
    const [checkin, setCheckIn] = useState("");
    const [checkout, setCheckOut] = useState("");
    const [adult, setAdult] = useState("");
    const [child, setChild] = useState("");
    const [request, setRequest] = useState("");

    const [loading, setLoading] = useState(false);

    //calcuting number of days
    const dayOne = new Date(checkin);
    const dayTwo = new Date(checkout);

    const timeDiff = dayTwo.getTime() - dayOne.getTime();

    const dayDiff = timeDiff / (1000 * 60 * 60 * 24);


    //quantity
    const [qty, setQty] = useState(1);

    const { id } = useParams();

    const [singleRoom, setSingleRoom] = useState([]);

    //calculating total
    const total = qty * singleRoom.roomprice;

    const grandTotal = total * dayDiff;

    const getYenagoaRooms = async () => {
        try {
            const { data: { data } } = await newRequest.get(`/room/${id}`);
            setSingleRoom(data);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getYenagoaRooms()
    }, [])

    const roomname = singleRoom.roomname;
    const roomprice = singleRoom.roomprice;
    const roomlocation = singleRoom.roomlocation;

    const handleBooking = async (e) => {
        e.preventDefault();
        const bookingData = {
            firstname,
            lastname,
            email,
            phone,
            country,
            city,
            arrival,
            address,
            checkin,
            checkout,
            adult,
            child,
            request,
            roomname,
            roomprice,
            roomlocation,
            qty,
            grandTotal,
        }
        setLoading(true);

        try {
            await newRequest.post('/newbooking', bookingData);
            setLoading(false);
            toast.success('Booking Successful!');
            setTimeout(() => {
                navigate('/successful-booking');
            }, 3000);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }

        setFirstName("");
        setLastName("");
        setEmail("");
        setPhone("");
        setCountry("");
        setCity("");
        setArrival("");
        setAddress("");
        setCheckIn("");
        setCheckOut("");
        setAdult("");
        setChild("");
        setRequest("");
    }

    const navigate = useNavigate();
    return (
        <>
            <Navbar />

            <div className='booking'>
                <h1>Booking: {singleRoom.roomname} </h1>
            </div>

            <div className='bookingWrapper'>

                <div className='bookingContainer'>
                    <div className='bookingLeft'>
                        <h2>Selected Room</h2>

                        <div className='bookingBox'>
                            <span>Room Type</span>
                            <div className='bookingImg'>
                                <img src={singleRoom ? singleRoom.roomimage : "/assets/yenagoaImages/executiveyen.jpeg"} alt="" />
                            </div>
                            <div className='bookingContent'>
                                <span>{singleRoom.roomname}</span> <br/>
                                <span>Location: {singleRoom.roomlocation}</span>
                                <p> ₦ {singleRoom.roomprice?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}/ Per Night</p>

                                <div className='bookingQty'>
                                    <p>Quantity</p>
                                    <div className='qtyBtn'>
                                        <AiFillMinusCircle
                                            onClick={() => setQty(Math.max(1, qty - 1))}
                                            size={25} className='bookingIcon' />
                                        <p>{qty}</p>
                                        <AiFillPlusCircle
                                            onClick={() => setQty(qty + 1)}
                                            size={25} className='bookingIcon' />
                                    </div>
                                </div>

                                <div className='bookingTotal'>
                                    <span>TOTAL:  ₦ {dayDiff ? (
                                        <>
                                            {grandTotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                        </>
                                    ) : (
                                        <>
                                            {total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                        </>
                                    )}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='bookingRight'>
                        <h2>Billing Details</h2>

                        <div className="bookingForm">
                            <form onSubmit={handleBooking}>
                                <div className='bookingFormCol'>
                                    <div className='formGroup'>
                                        <label htmlFor=''>First Name</label>
                                        <input
                                            value={firstname}
                                            onChange={(e) => setFirstName(e.target.value)} type="text" placeholder='First Name' />
                                    </div>

                                    <div className='formGroup'>
                                        <label htmlFor=''>Last Name</label>
                                        <input
                                            value={lastname}
                                            onChange={(e) => setLastName(e.target.value)}
                                            type="text" placeholder='Last Name' />
                                    </div>
                                </div>

                                {/* email and phone */}
                                <div className='bookingFormCol'>
                                    <div className='formGroup'>
                                        <label htmlFor=''>Email Address</label>
                                        <input
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            type="text" placeholder='Enter Email Address' />
                                    </div>

                                    <div className='formGroup'>
                                        <label htmlFor=''>Phone</label>
                                        <input
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                            type="text" placeholder='+23470xxxxxx' />
                                    </div>
                                </div>


                                {/* country and city */}
                                <div className='countrySection'>
                                    <div className='formGroup'>
                                        <label htmlFor=''>Country</label>
                                        <input
                                            value={country}
                                            onChange={(e) => setCountry(e.target.value)}
                                            type="text" placeholder='Enter Country' />
                                    </div>

                                    <div className='formGroup'>
                                        <label htmlFor=''>City</label>
                                        <input
                                            value={city}
                                            onChange={(e) => setCity(e.target.value)}
                                            type="text" placeholder='Enter City' />
                                    </div>

                                    <div className='formGroup select'>
                                        <label htmlFor=''>Arrival Time</label>
                                        <select
                                            value={arrival}
                                            onChange={(e) => setArrival(e.target.value)}
                                            name="arrivalTime">
                                            <option value="Select">Select Time</option>
                                            <option value="10:00 - 11:00 AM">10:00 - 11:00 AM</option>
                                            <option value="12:00 - 13:00 PM">12:00 - 13:00 PM</option>
                                            <option value="13:00 - 14:00 PM">13:00 - 14:00 PM</option>
                                            <option value="17:00 - 18:00 PM">17:00 - 18:00 PM</option>
                                        </select>
                                    </div>

                                </div>
                                {/* address */}
                                <div className='bookingFormCol'>
                                    <div className='formGroup address'>
                                        <label htmlFor=''>Address</label>
                                        <input
                                            value={address}
                                            onChange={(e) => setAddress(e.target.value)}
                                            type="text" placeholder='Enter Address' />
                                    </div>
                                </div>

                                {/* Check in data and time */}
                                <div className='bookingFormCol'>
                                    <div className='formGroup'>
                                        <label htmlFor=''>Check In Date</label>
                                        <input
                                            value={checkin}
                                            onChange={(e) => setCheckIn(e.target.value)}
                                            type="date" placeholder='01/01/2023' />
                                    </div>

                                    <div className='formGroup'>
                                        <label htmlFor=''>Check Out Date</label>
                                        <input
                                            value={checkout}
                                            onChange={(e) => setCheckOut(e.target.value)}
                                            type="date" placeholder='01/01/2023' />
                                    </div>
                                </div>


                                {/* adult and children */}

                                {/* Check in data and time */}
                                <div className='bookingFormCol'>
                                    <div className='formGroup select'>
                                        <label htmlFor=''>No. of Adult</label>
                                        <select
                                            value={adult}
                                            onChange={(e) => setAdult(e.target.value)}
                                            name="adult">
                                            <option value="select">Select Adult</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                        </select>
                                    </div>

                                    <div className='formGroup select'>
                                        <label htmlFor=''>No. of Child</label>
                                        <select
                                            value={child}
                                            onChange={(e) => setChild(e.target.value)}
                                            name="child">
                                            <option value="select">Select Child</option>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                        </select>
                                    </div>
                                </div>


                                {/*  specia request*/}
                                <div className='bookingFormCol'>
                                    <div className='formGroup textarea'>
                                        <label htmlFor=''>Special Request</label>
                                        <textarea
                                            value={request}
                                            onChange={(e) => setRequest(e.target.value)}
                                            cols="10" rows="10" placeholder='let us know if you have any special request.' />
                                    </div>

                                </div>

                                {/* button */}
                                <button>{loading ? "Processing..." : "Submit Booking"}</button>
                            </form>
                        </div>
                        <ToastContainer />


                    </div>
                </div>

                
            </div>
            <Footer />
        </>
    )
}

export default BookingPage;