import React, { useEffect, useState } from "react";

import "./AyallaVIP.css";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import newRequest from '../../../utils/newRequest';
import { Link } from "react-router-dom";
//import { MdOutlinePix } from "react-icons/md";

const AyallaVIP = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getVipRooms = async () => {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await newRequest.get("/vip");
      setData(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getVipRooms();
  }, []);

  return (
    <>
      <Navbar />

      <div className="yenagoa">
        <h1>Ayalla VIP Business Apartment</h1>
        {/* <p>
          Ayalla VIP Business Apartment is decorated and designed for your
          comfort and relaxation.
        </p> */}
      </div>

      <div className="yenagoaWrapper">
        {/* <h1>Coming Soon!</h1> */}
        <div className="vip-card-container">
          {loading ? (
            <h2>loading data...</h2>
          ) : (
            data?.map((item, index) => (
                <div key={index} className="vip-card">
                    <img src={item.roomimage} alt="" />
                    <div className="vip-card-body">
                        <span>{item.roomname}</span>
                        <p> ₦ {item.roomprice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}/ Per Night</p>
                    </div>

                    <Link to={`/booking/${item._id}`} className="link">
                        <button>BOOK NOW</button>
                    </Link>
                </div>
            ))
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AyallaVIP;
