import React from 'react';
import './Welcome.css';

const Welcome = () => {
    return (
        <div className='welcome'>
            <h1>Welcome to Ayalla Hotels Limited</h1>
            <div className='divider' />
            <div className='divider middle' />
            <div className='divider' />
            <div className='welcomeWrapper'>
                <div className='welcomeCol Left'>
                  <div className='imgSec'>
                  <img src="/assets/ayalla-hotels-ltd.jpg" alt="ayalla hotels ltd" />
                  </div>
                </div>

                <div className='welcomeCol'>
                    <span>About Us</span>
                    <h3>Delivering Quality Hospitality Services Since 2007</h3>
                    <p>
                        Ayalla Hotels Limited was established and founded on the 16th day
                        of August 2007. The hotel is an emerging brand in the hospitality 
                        industry and operates with a sense of urgency to give maximum 
                        satisfaction to every guest. We have on board a team of discipline 
                        workforce whose business is to render phenomenal services that will 
                        create a lasting impression about us.
                    </p>

                    <button>Read more</button>
                </div>
            </div>
        </div>
    )
}

export default Welcome;