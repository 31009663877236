
import React, { createContext, useEffect, useState } from 'react';
import newRequest from './utils/newRequest';

const Context = createContext();

const AppContext = ({ children }) => {

    const [roomData, setRoomData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [userInfo, setUserInfo] = useState({})


    //login user account

    async function userLogin(data) {
        setLoading(true)
        try {
            const res = await newRequest.post('/user/login', data);
            // console.log(res.data.payload, 'data')
            let userInfo = res.data.payload;
            setUserInfo(res.data.payload);
            localStorage.setItem('userInfo', JSON.stringify(userInfo));
            setLoading(false);
        } catch (err) {
            // console.log(err.response.data);
            setError(err.response.data);
            setLoading(false);
        }
    }


    // signOut user
    async function userLogOut() {
        setLoading(true)
        try {
            await newRequest.post('/user/logout')
            localStorage.removeItem('userInfo');
            setLoading(false);
        } catch (err) {
            console.log(err);
            setError(err);
            setLoading(false);
        }
    }



    const getAllRooms = async () => {
        setLoading(true);
        try {
            const { data: { data } } = await newRequest.get('/rooms');
            setRoomData(data);
            setLoading(false);
        } catch (err) {
            console.log(err)
            setLoading(false);
        }
    }

    useEffect(() => {
        getAllRooms();
    }, [])


    const data = { roomData, loading, error, userInfo, userLogin, userLogOut }

    return (
        <Context.Provider value={data}>
            {children}
        </Context.Provider>
    )
}

export { Context, AppContext };