import React from 'react';
import './Home.css';
import Slide from '../../components/Slides/Slide';
import SearchForm from '../../components/SearchForm/SearchForm';
import Welcome from '../../components/WelcomeSection/Welcome';
import Hotel from '../../components/OurHotels/Hotels';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbar/Navbar';
import Gallery from '../../components/Gallery/Gallery';

const Home = () => {
    return(
    
        <div className="home"> 
            <Navbar />
            <div className="homeWrapper">
               <Slide/>
             
               <div className="searchContainer">
               <SearchForm />
               </div>
               <Welcome/>
               <Hotel/>
               <Gallery />
               <Footer/>
            </div>
        </div>
  
    )
}

export default Home;