import React, { useContext, useState } from 'react';
import { MdSearch } from "react-icons/md";
import './SearchForm.css';
import { Context } from '../../Context';
import { Link } from 'react-router-dom';


const SearchForm = () => {

    const { roomData } = useContext(Context);

    const [filterData, setfilterData] = useState([]);
    const [input, setInput] = useState("")


    const handleSearch = (e) => {
        const searchWord = e.target.value;
        setInput(searchWord);
        const newFilter = roomData?.filter(rooms => {
            return rooms.roomname?.toLowerCase().includes(searchWord.toLowerCase())
                || rooms.roomlocation?.toLowerCase().includes(searchWord.toLowerCase());
        })

        if (searchWord === "") {
            setfilterData([])
        } else {
            setfilterData(newFilter);
        }
    }


    return (
        <>
            <div className="search">
                <div className="searchWrapper">
                    <div className="searchLeft">
                        <MdSearch size={25} style={{ color: 'darkblue' }} />
                        <input
                            value={input}
                            onChange={handleSearch}
                            type="text" placeholder='Search your favorite room and book. Try "Executive Rooms"' />
                    </div>
                </div>

            </div>

            <div className='searchContent'>
                {filterData.length != 0 && (
                    <>

                        {filterData?.slice(0, 3)?.map((data) => (
                            <div key={data._id} className='searchBox'>
                                <Link to={`/booking/${data._id}`} className="link searchBox">
                                <div className='sBoxLeft'>
                                    <img src={data.roomimage} alt='' />
                                </div>

                                <div className='sBoxRight'>
                                    <span>{data.roomname}</span>
                                    <span>₦{data.roomprice?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                    <span>Room Location: {data.roomlocation}</span>
                                </div>
                            </Link>
                            </div>
                           
                        ))}

            </>
                )}
        </div >

        </>
    )
}

export default SearchForm;